<template>
  <v-dialog :value="crateModal" fullscreen>
    <v-card>
      <v-card-title>Krattenregistratie</v-card-title>
     <v-row class="d-flex justify-center">
        <v-col cols="12" sm="4">
          <div class="form-group">
            <div class="search-supplier">
          <search-supplier          
        :searchString="searchSupplierString"
        :noResults="false"
        @setSearchString ="setSearchSupplierString"
        @get-results="getSupplierResults"
        @clear-input="clearSupplier"
        ></search-supplier>
        </div>
        <div class="date-picker">
        <date-picker         
          :value="thisItem.date"
          label="Datum"
          :dateFormatted="dateFormatted"
          @changeDate="changeDate"
          ></date-picker>
          </div>
          <v-text-field
          class="text-field"
            label="Ordernummer"
            ref="orderNumber"
            v-model="thisItem.orderNumber"
            :rules="[rules.required]"
            hide-details
          ></v-text-field>
          <v-text-field
          class="text-field"
            type="number"
            label="Inkomend"
            ref="inbound"
            v-model="thisItem.inbound"
            hide-details
          ></v-text-field>
          <v-text-field
          class="text-field"
            type="number"
            label="Uitgaand"
            ref="outbound"
            v-model="thisItem.outbound"
            hide-details
          ></v-text-field>
          <v-select v-model="thisItem.crateType" :items="crateTypes" label="Type krat"></v-select>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <v-footer absolute class="d-flex justify-center">
      <v-btn color="primary" tile @click="closeCrateModal">Annuleer</v-btn>
      <v-btn color="primary" tile @click="insertCrateItem">Opslaan</v-btn>
    </v-footer>
  </v-dialog>
</template>

<script>
import searchSupplier from '@/components/searchSupplier.vue'
import datePicker from '@/components/datePicker.vue'
export default {
  name: "crateModal",
  props: {
    crateModal: Boolean,
    item: Object
  },
  data() {
    return {
      dateFormatted:  this.formatDate(
        new Date().toISOString().split("T")[0]
      ),
      thisItem: {...this.item,date: new Date(Date.now()).toISOString().split("T")[0],},
      searchSupplierString: "",      
      rules: {
        required: value => !!value || "Veld verplicht.",
        counter: value => value.length <= 20 || "Max 20 karakters"
      },
      crateTypes: [
        { text: "Kipkrat", value: "chickenCrate" },
        { text: "Blokpallet", value: "IndustrialPallet" },
        { text: "Europallet", value: "euroPallet" }
      ]
    }
  },
  methods: {
    changeDate(date){
        this.thisItem.date = date
      this.dateFormatted = this.formatDate(date)
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split("-")
      return `${day}-${month}-${year}`
    },
    setSearchSupplierString(val) {
      this.searchSupplierString = val
    },
    getSupplierResults(val){
      this.thisItem.supplier = val
      this.thisItem.supplierNumber = val.supplierNumber
    },
    clearSupplier(){
this.thisItem.supplier = {}
this.searchSupplierString = ""
    },
    closeCrateModal(){
      this.$emit("close-crate-modal")
    },
    insertCrateItem(){
      this.thisItem.inbound = parseInt(this.thisItem.inbound) || 0
      this.thisItem.outbound = parseInt(this.thisItem.outbound) || 0
      this.$emit("insert-crate-item",this.thisItem)
    }
  },
  mounted() {
    if(this.item&&this.item.supplier&&this.item.supplier.name){
      this.searchSupplierString = this.item.supplier.name
    }
  },
  components: {
    searchSupplier,datePicker
  },
}
</script>

<style scoped>
.form-group * {
  max-width: 300px;
}
.form-group .search-supplier {
  max-width: 300px;
  margin-bottom: 10px;
}
.form-group .date-picker {
  max-width: 300px;
  margin-bottom: 10px;
}
.form-group .text-field {
  margin: 10px 0;
}
</style>